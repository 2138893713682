.container {
    display: flex;
    flex-direction: column;
    align-items: center; 
  }
  
  .container h1 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .section {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    width: 100%;
    animation: btn 1.5s ease-in 1.5s forwards;

  }
  
  .image {
    flex: 1;
    max-width: 100%;
    width: 100%;
    overflow: hidden; 
  }

  .image img{
    height: 100%;
    width: 100%;
  }
  
  .text {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text p{
    font-size: 1.2rem;
    font-weight: 500;
  }

  @keyframes btn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

  .reverse_section{
    display: flex;
  }
  
  .story_title{
    font-size: 1.9rem !important;
    margin-bottom: 0;
  }
  
  @media (max-width:768px){
    .section{
      flex-direction: column;
    }
    .reverse_section{
      flex-direction: column-reverse;
    }
    .text p{
      font-size: 1rem;
      font-weight: 400;
    }
  }