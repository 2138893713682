.menu-container {
  background-color: #2d2828;
  color: white;
  height: 100vh;
  width: 25%;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 1000;
  overflow-y: auto;
  transition: right .5s ease-in-out;
}

.menu-container.open {
  right: 0;
}

.menu-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-container li {
  /* font-weight: bold; */
  padding: 10px 0;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.menu-list {
  gap: 1.5rem;
  font-size: 1.1rem;
  padding: 0.5rem;
}

.menu-list li {
  color: white;
}

.menu-list li:hover,
.services-menu.open > .white-link {
  color: #fec239;
}

.nested-list {
  list-style-type: none;
  padding-left: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.nested-list li {
  color: white;
  transition: 1s ease;
  font-weight: lighter;
  margin-left: 1rem;
}

.nested-list li:hover {
  color: #ffdb8e !important;
}

.services-menu {
  cursor: pointer;
}

.services-menu.open .nested-list {
  max-height: 500px; /* Adjust this value based on the content's height */
  opacity: 1;
}

.icon-spacing {
  margin-left: 1rem;
}

.white-link {
  color: white;
  text-decoration: none;
}

.white-link:hover {
  color: #fec239 !important;
}

.highlight {
  color: #fec239 !important;
}

@media (max-width: 768px) {
  .menu-container {
    width: 50%;
  }
}

@media (max-width: 425px) {
  .menu-container {
    width: 75%;
  }
}
