.nav {
  z-index: 1000;
}

.rm-logo {
  width: 4rem;
  height: 4rem;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 18px;
  height: 16px;
  border: 0;
  padding: 0;
  background: transparent;
  position: relative;
  cursor: pointer;
  background-color:  rgb(255, 192, 29,0.5);
}

.menu-icon span {
  display: flex;
  width: 100%;
  background: white;
  height: 2px;
  transition: all 0.5s;
  position: absolute;
  margin-right: 1rem;
}

/* .menu-icon span:nth-child(1) {
  width: 30px;
}

.menu-icon span:nth-child(2) {
  left: 50%;
  top: 50%;
}

.menu-icon span:nth-child(3) {
  bottom: 0;
  width: 30px;
} */


.menu-toggle {
  display: flex; 
  cursor: pointer; 
  align-items: center;
}

.menu-toggle button {
  display: flex; 
  flex-direction: column; 
  padding: 0;
  border: none; 
  background-color: transparent;
  width: fit-content; 
  gap: 0.3rem;
  background-color: rgb(255, 192, 29,0.5);
  padding: 0.5rem;
}

.menu-toggle button span {
  width: 1rem; 
  height: 3px;
  background-color: white;
}


.menu-toggle button span:first-child{
  width: 35px;
}

.menu-toggle button span:nth-child(2){
  width: 25px;
}


.menu-toggle button span:nth-child(3){
  width: 35px;
}
