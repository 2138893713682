* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 4px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgb(255, 192, 29);
  }
  
  ::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: white;

  }
  

body {
    margin: 0;
    padding: 0;
    --color: rgb(255, 192, 29);
    font-family: 'poppins', 'roboto';
    --font-small: 14px;
    --font-medium: 16px;
    --font-large: 20px;
    --font-xlarge: 24px;
}

p{
    text-align: justify;
    margin-bottom: 0;
    line-height: 2rem;
}

.theme-color {
    background-color: var(--color);
}

.font-small {
    font-size: var(--font-small);
}

.font-medium {
    font-size: var(--font-medium);
}

.font-large {
    font-size: var(--font-large);
}

.font-xlarge {
    font-size: var(--font-xlarge);
}

@media (max-width: 320px) {
    * {
        margin: 0;
        padding: 0;
    }
}