@import url('https://fonts.googleapis.com/css?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Material+Icons+Outlined');

.careersContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/serviceBanners/TEAM 04.jpg');
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
  text-align: center;
}

.title {
  font-size: 3.5rem;
  margin-bottom: 20px;
  font-weight: 400;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: black;
  padding: 54px;
  gap: 16px;
  opacity: .7;
  justify-content: center;
}

.input {
  margin: 5px;
  padding: 10px;
  width: 90%;
  max-width: 300px;
}

.fileInputWrapper {
  position: relative;
  width: 90%;
  max-width: 300px;
  margin: 5px;
}

.dropArea {
  width: 100%;
  padding: 10px;
  border: 2px dashed #ccc;
  text-align: center;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}

.fileInputLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 2px dashed #ccc;
  pointer-events: none;
}

.fileInputLabel .material-icons {
  margin-right: 8px;
}

.sub_btn {
  margin: 5px;
  padding: 10px;
  width: 10%;
}

.sub_btn:hover {
  background-color: rgb(255, 192, 29);
  color: white;
}

.inputBox {
  position: relative;
  width: 25%;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.inputBox input[type="submit"] {
  flex-grow: 1;
  width: 100%;
  background: rgb(255, 192, 29);
  color: rgb(255, 192, 29);
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border: 1px solid rgb(255, 192, 29);
  border-radius: 35px;
  transition: 0.5s;
}

.view_more_btn {
  display: flex;
  align-items: center;
  background: rgb(255, 192, 29);
  color: black;
  cursor: pointer;
  border: 1px solid rgb(255, 192, 29);
  padding: 0.8rem;
  font-size: 0.9rem;
  transition: 0.5s;
  border-radius: 35px;
  text-decoration: none;
  text-align: center;
  width: 100%;
}

.view_more_btn_text {
  flex-grow: 1; 
  text-align: center; 
  font-weight: 500;
}

.inputBox input[type="submit"]:hover {
  background: rgb(255, 192, 29);
  color: #fff;
}

.view_more_btn p {
  margin: 0;
  text-align: center;
}

.icon_right {
  background-color: rgb(255, 192, 29);
  color: black;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  font-size: 1.5rem;
}


@media (min-width: 1200px) {
  .title {
    font-size: 4.5rem;
  }

  .form {
    padding: 54px 100px;
  }
}


@media (min-width: 1024px) {
  .form {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .input {
    width: 300px;
  }

}

@media (max-width:768px){
  .form{
    padding: 0.7rem;
    /* width: fit-content; */

  }
  .sub_btn{
    width: 300px;
    padding: 5px;
  }

  .title{
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .inputBox {
    width: 60%;
  }  
}

@media (max-width:500px) {
  .inputBox {
    width: 60%;
  }  
}
