/* HTML: <div class="loader"></div> */
.loader-container{
    position: fixed;
    background-color: black;
    opacity: 0.9;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

}

.loader {
  width: 60px;
  aspect-ratio: 1;
  color: #ffa516;
  background: linear-gradient(currentColor 0 0) 100% 0,
    linear-gradient(currentColor 0 0) 0 100%;
  background-size: 50.1% 50.1%;
  background-repeat: no-repeat;
  animation: l7-0 1s infinite steps(1);
  position: absolute;

}
.loader::before,
.loader::after {
  content: "";
  position: absolute;
  inset: 0 50% 50% 0;
  background: currentColor;
  transform: scale(var(--s, 1)) perspective(150px) rotateY(0deg);
  transform-origin: bottom right;
  animation: l7-1 0.5s infinite linear alternate;
}
.loader::after {
  --s: -1, -1;
}
@keyframes l7-0 {
  0% {
    transform: scaleX(1) rotate(0deg);
  }
  50% {
    transform: scaleX(-1) rotate(-90deg);
  }
}
@keyframes l7-1 {
  49.99% {
    transform: scale(var(--s, 1)) perspective(150px) rotateX(-90deg);
    filter: grayscale(0);
  }
  50% {
    transform: scale(var(--s, 1)) perspective(150px) rotateX(-90deg);
    filter: grayscale(0.8);
  }
  100% {
    transform: scale(var(--s, 1)) perspective(150px) rotateX(-180deg);
    filter: grayscale(0.8);
  }
}
