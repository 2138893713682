.gallery {
  --_height: 320px;
  --_gap: 0.5rem;
  --_grid-rows: var(--_height) var(--_height);
  --_height-hover: calc(var(--_height) * 1.5);
  --_height-not-hover: calc(var(--_height) / 2);
  width: 102%;
  max-width: 100%;
  display: grid;
  gap: var(--_gap);
  z-index: 2;
}

@media (min-width: 720px) {
  .gallery {
    grid-template-columns: repeat(4, 1fr);
  }
}

.gallery > div {
  height: fit-content;
  display: grid;
  grid-template-rows: var(--_grid-rows);
  gap: var(--_gap);
  transition: grid-template-rows 300ms ease-in-out;
}

.gallery > div > article {
  position: relative;
}

.gallery > div > article > div {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #00000070;
  font-size: 0.65rem;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.15rem 0.5rem;
}

.gallery > div > article > div > span {
  font-size: 0.7rem;
  color: red;
}

.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery > div:has(> article:nth-child(1):hover) {
  --_grid-rows: var(--_height-hover) var(--_height-not-hover);
}

.gallery > div:has(> article:nth-child(2):hover) {
  --_grid-rows: var(--_height-not-hover) var(--_height-hover);
}

/* .gallery > div:has(> article:nth-child(3):hover) {
  --_grid-rows: var(--_height-not-hover) var(--_height-not-hover)
    var(--_height-hover);
} */

.gallery > div > article:hover {
  background-color: rgb(151, 152, 201);
  color: #fff;
}

.gallery-text {
  text-align: center;
  font-size: 5rem;
  font-family: "Rubik Lines";
  letter-spacing: 1px;
  color: white;
}

/* ------------BRAND----------------- */
.brand{
  display: flex;
  flex-direction:row ;

}

.brand img{
  width: 100px;
  height: 100px;
}

