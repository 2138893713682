.banner {
  position: relative;
  overflow: hidden;
  height: 50vh;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  pointer-events: none;
}

.bannerImage {
  object-fit: cover;
  height: 100%;
  transition: all 0.3s linear;
  animation: btn 0.5s ease-in 0.5s forwards;
  opacity: 0;
}

.title {
  position: relative;
  color: white;
  font-size: 5rem;
  top: 0px;
  z-index: 2;
  animation: title 2s ease-in forwards;
}

@keyframes title {
  0% {
    top: -100vh;
  }

  100% {
    top: 0;
  }
}

.title_description_services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.desc_container {
  width: 100%;
  margin-top: 40px;
}

.description {
  position: relative;
  bottom: 0;
  font-size: 1.2rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  text-align: justify;
  line-height: 2rem;
  animation: content 1s ease-in forwards;
}

@keyframes content {
  0% {
    bottom: -10vh;
  }

  100% {
    bottom: 0;
  }
}

.services_images_list {
  display: flex;
  background-color: rgb(255, 192, 29);
  /* height: 400px; */
  transition: all 0.3s linear;
  animation: btn 1.5s ease-in 1.5s forwards;
  opacity: 0;
}

.branding {
  animation: btn 1.5s ease-in 1.5s forwards;
  opacity: 0;
}

@keyframes btn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.li_div {
  flex: 1;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.list {
  list-style: none;
  padding-left: 0;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0;
  margin-left: 1.5rem;
}

.list li {
  flex: 0 0 48%;
  margin-bottom: 10px;
  cursor: pointer;
  height: 25px;
}

.imageContainer {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.horizontal_images_container {
  display: flex;
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
  height: 280px;
  position: relative;
}

.horizontal_images_wrapper {
  display: flex;
  width: max-content;
  animation: scroll 30s linear infinite;
}

.horizontal_image {
  width: 300px;
  height: 250px;
  margin-right: 10px;
}
.branding_images_container {
  width: 100%;
}

.brand_images_row {
  margin-bottom: 20px;
  overflow-x: hidden;
}

.brand_images {
  display: flex;
  flex-wrap: nowrap;
  animation: scroll 100s linear infinite;
}

.branding_image {
  flex: 0 0 auto;
  margin-right: 10px;
  width: 250px;
  height: auto;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media (min-width: 1024px) {
  .banner {
    height: 70vh;
  }
}

@media (max-width: 768px) {
  .branding_image {
    width: 200px;
  }

  .title {
    font-size: 3rem;
  }

  .description {
    font-size: 1rem;
  }

  .imageContainer {
    clip-path: none;
  }

  .services_images_list {
    flex-direction: column;
  }

  .list {
    margin-top: 1rem;
  }
}

@media (max-width: 600px) {
  /* .branding_image {
    width: 150px;
  } */
  .description {
    font-size: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .list {
    font-size: 0.7rem;
    /* list-style-type: circle; */
    padding-bottom: 0.5rem;
  }

  .desc_container {
    margin-top: 1.5rem;
  }
}
