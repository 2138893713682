.contact_wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
}

.contact_content {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 0 auto;
}

.form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.map {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.title_description_services {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 192, 29);
}
  
.title {
    display: inline-block !important;
    margin-top: -60px;
    z-index: 3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    font-size: 4rem;
    font-weight: 700;
    padding: 10px;
}

.map{
    width: 100%;
}
  
.desc_container {
    /* margin-top: 40px; */
    width: 100%;
}
  
.description {
    font-weight: 600;
    font-size: 1.5rem;
}



@media (max-width: 767px) {
    .contact_content{
        flex-direction: column;
    }
    
  }
  