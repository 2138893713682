@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");

.home-conatiner{
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border: 1px solid #ccc;
  overflow: auto;
  max-width: 100%;
  box-sizing: border-box;
}

.welcome-screen {
  background-image: url("../../assets//images/Lapsize\ 1440\ x\ 410px\ new.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
  background-position: clamp(79%, 89.5%, 100%);
  transition: opacity 2s ease, transform 2s ease;
  transform: translateX(0%);
  z-index: -1;
}

.welcome-screen.no-blur {
  filter: blur(0px);
  opacity: 0; 
}

.tagline {
  position: absolute;
  left: 3%;
  top: 85%;
  color: black;
  font-size: 1rem;
}

@media (min-width: 2560px) {
  .tagline {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) {
  .welcome-screen {
    background-image: url("../../assets//images/Lapsize\ 1440\ x\ 410px\ new.jpg");
  }
}

@media (max-width: 768px) {
  .tagline {
    white-space: nowrap;
  }
  .welcome-screen {
    background-image: url("../../assets//images/Tabsize\ 768\ x\ 267px.jpg");
    background-position: clamp(96%, 89.5%, 100%);
  }
}

@media (max-width:500px){
  .tagline {
    font-size: 1rem;
    white-space: normal;
    overflow-wrap: break-word;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .welcome-screen {
    background-image: url("../../assets//images/Mobilesize\ \ 320\ x\ 267px.jpg");
    background-position: clamp(91%, 89.5%, 100%);
  }
}

@media (max-width: 425px) {
  .welcome-screen {
    background-position: clamp(90%, 89.5%, 100%);
  }
  
}

@media (max-width: 349px) {
  .tagline {
    white-space: normal;
    overflow-wrap: break-word;
    text-align: left;
    font-size: 0.9rem;
  }
  .welcome-screen {
    background-position: clamp(91%, 89.5%, 100%);
  }
  
}

@media (max-width: 320px) {

  .welcome-screen {
    background-position: clamp(96%, 89.5%, 100%);
  }
  
}


.image-gallery {
  display: flex;
  flex-direction: column;
  place-content: center;
  font-family: system-ui, sans-serif;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  align-items: center;
  gap: 1.5rem;
  background-size: cover;
}

.img-container {
  display: flex;
  animation: gradient 5s ease infinite;
  background-size: 400% 400%;
}

.img-container > div {
  flex: 1;
}

.img-text-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.img-text-content p {
  text-align: center;
  line-height: 1.6;
  min-height: 5em;
  margin: 0;
  white-space: pre-line;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.view-btn {
  border-radius: 2rem / 50%;
  background: transparent;
  font-family: "DM Serif Display", serif;
  border: 1px solid black;
  color: black;
}

.view-btn:hover {
  background: linear-gradient(62deg, #f6ecb1, #ffdb8e);
  color: rgb(85, 82, 82);
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-shape-divider-bottom-1717681059 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1717681059 svg {
  position: relative;
  display: block;
  width: calc(134% + 1.3px);
  height: 115px;
}

.custom-shape-divider-bottom-1717681059 .shape-fill {
  fill: #ffffff;
}

.custom-shape-divider-bottom-1717681059 {
  position: relative;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  display: none;
}

.custom-shape-divider-bottom-1717681059 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 120px;
}

.custom-shape-divider-bottom-1717681059.visible {
  display: block;
}

.view-more-btn {
  display: flex;
  align-items: center;
  background: white;
  color: black;
  cursor: pointer;
  border: 1px solid rgb(255, 192, 29);
  padding: 0.6rem;
  font-size: 0.9rem;
  transition: 0.5s;
  border-radius: 35px;
  text-decoration: none;
  text-align: center;
  width: 12rem;
}

.view-more-btn-text {
  flex-grow: 1; 
  text-align: center; 
}

.view-more-btn p {
  margin: 0;
  text-align: center;
}

.icon-right {
  background-color: rgb(255, 192, 29);
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  font-size: 1.5rem;
}

.view-more-btn:hover {
  background: rgb(255, 192, 29);
  color: white;
}

.awards-section {
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
