@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-size: cover;
  background-position: center;
  position: relative;
}

.wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.section {
  position: relative;
  z-index: 3;
  padding-top: 50px;
  padding-bottom: 50px;
}

.container {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.sectionHeader {
  margin-bottom: 50px;
  text-align: center;
}

.sectionHeader p {
  color: #fff;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.contactInfo {
  width: 50%;
}

.contactInfoItem {
  display: flex;
  margin-bottom: 30px;
}

.contactInfoIcon {
  height: 30px;
  width: 30px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  justify-content: center;
  cursor: pointer !important;
}

.contactInfoContent {
  margin-left: 20px;
}

.contactInfoContent h4 {
  color: rgb(255, 192, 29);
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contactInfoContent p {
  color: #fff;
  font-size: 1em;
}

.links{
  padding-left: 3rem;
  gap: 1rem;
}

.links p{
  color: white;
}

.contactForm {
  background-color: #fff;
  padding: 40px;
  width: 45%;
  padding-bottom: 20px;
  padding-top: 20px;
  border-radius: 10px;
}

.contactForm h2 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.inputBox {
  position: relative;
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.inputBox input,
.inputBox textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.inputBox input:focus ~ span,
.inputBox textarea:focus ~ span {
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
}

.inputBox input[type="submit"] {
  flex-grow: 1; /* Takes up remaining space */
  width: 100%;
  background: #fff;
  color: rgb(255, 192, 29);
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border: 1px solid rgb(255, 192, 29);
  border-radius: 35px;
  transition: 0.5s;
}

.inputBox input[type="submit"]:hover {
  background: rgb(255, 192, 29);
  color: #fff;
}

.view_more_btn {
  display: flex;
  align-items: center;
  background: white;
  color: black;
  cursor: pointer;
  border: 1px solid rgb(255, 192, 29);
  padding: 0.8rem;
  font-size: 0.9rem;
  transition: 0.5s;
  border-radius: 35px;
  text-decoration: none;
  text-align: center;
  width: 100%;
}

.view_more_btn_text {
  flex-grow: 1; 
  text-align: center; 
}

.view_more_btn p {
  margin: 0;
  text-align: center;
}

.icon_right {
  background-color: rgb(255, 192, 29);
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  font-size: 1.5rem;
}


.view_more_btn:hover {
  background: rgb(255, 192, 29);
  color: white;
}
@media (max-width: 991px) {
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .row {
    flex-direction: column;
  }

  .contactInfo {
    margin-bottom: 40px;
    width: 100%;
  }

  .contactForm {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .sectionHeader {
    margin-bottom: 1.6rem;
  }
  .sectionHeader h1 {
    font-size: 1.5rem;
  }
  .sectionHeader p {
    font-size: 0.9rem;
  }
  .contactInfo {
    margin-bottom: 0.4rem;
  }
  .contactInfoItem {
    margin-bottom: 1rem;
  }
  .contactInfoIcon {
    height: 40px;
    width: 40px;
    font-size: 1rem;
  }
  .contactForm {
    padding: 1rem;
  }

  .contactForm h2 {
    font-size: 1.8em;
  }

  .contactInfoContent h4 {
    font-size: 1.2rem;
  }

  .contactInfoContent p {
    font-size: 0.8em;
  }

  .inputBox {
    margin-top: 0;
  }
}
