.container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  text-align: center;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding-top: 3rem;
  padding-bottom: 7rem;
  overflow: hidden;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
.carouselContainer {
  overflow: hidden;
  width: 100%;
}
.brands,
.awards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
}

.carousel {
  display: flex;
  animation: scroll 100s linear infinite;
}

.imageContainer {
  min-width: 25%;
  box-sizing: border-box;
  padding: 0 10px;
}

.award_image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.image {
  width: 100%;
  height: 200px;
  object-fit: contain !important;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1000%);
  }
}

.carousel_award {
  display: flex;
  animation: move 20s linear infinite;
}

@keyframes move {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .imageContainer {
    min-width: 33%;
  }
}

@media (max-width: 500px) {
  .imageContainer {
    min-width: 40%;
  }
}

@media (max-width: 425px) {
  .imageContainer {
    min-width: 60%;
  }

  .brands,
  .awards {
    gap: 1rem;
  }
}
