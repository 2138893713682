.workContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.8rem;
}

.workContainer h1 {
  padding: 2rem;
}

.parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 100%;
}

.video_parent {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}

.videogrid{
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
  animation: btn 1.5s ease-in 1.5s forwards;
}

.image {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
  animation: btn 1.5s ease-in 1.5s forwards;
}

@keyframes btn {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

@media (max-width: 768px) {
  .parent {
      grid-template-columns: repeat(3, 1fr);
  }
  .video_parent {
    grid-template-columns: repeat(1, 1fr);
}
}

@media (max-width: 425px) {
  .parent {
      grid-template-columns: repeat(2, 1fr);
      gap: 3px;
  }

  .image {
      padding: 3px;
  }
 
}

