.banner {
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0);
  z-index: 1;
  pointer-events: none;
  opacity: 0.4;
}

.bannerImage {
  object-fit: cover;
  height: 100%;
  animation: btn 0.5s ease-in 0.5s forwards;
  opacity: 0;
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}


.title_description_services {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 192, 29);
}

.title {
  color: white;
  font-size: 5rem;
  text-align: center;
  z-index: 2;
  animation: btn 1.5s ease-in 1.5s forwards;
  opacity: 0;
}
@keyframes btn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.desc_container {
  margin-top: 40px;
}

.description {
  position: relative;
  font-size: 1.2rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  text-align: justify;
  line-height: 2rem;
  animation: content 1s ease-in forwards;

}
@keyframes content {
  0% {bottom: -10vh;}
  100% {bottom: 0;}
}



.services_images_list{
  display: flex;
  flex-basis: 1;
  height: 600px;
  background-color:black;
}

.li_div {
  flex: 1;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
 
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.li_div h1{
  margin-top: 1.5rem;
  opacity: 0;
  transform: translateY(1000px);
  animation: slideUp .8s ease forwards .5s;
}

.li_div p{
  line-height: 2rem;
  opacity: 0;
  transform: translateY(50px);
  animation: slideUp .8s ease-in-out  forwards 1s;
}

.list {
  list-style: none;
  padding-left: 0;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
}

.list li {
  flex: 0 0 48%; 
  margin-bottom: 10px; 
}


.imageContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center; 
  /* height: 400px;  */
  overflow: hidden; 
}

.we{
  clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%); 

}

.image{
 width: 100%;
 height: 100%;
 object-fit: cover;
 animation: slideIn 1.5s ease-in-out forwards;

}

.guide{
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
}

@media (max-width:768px){
  .we{
    clip-path: none;
  }

  .guide{
    clip-path: none
  }
  .services_images_list{
    flex-direction: column;
    height: auto;
  }
}

@media (max-width:425px){
  .description{
    font-size: 1rem;
  }
  .title{
    font-size: 3rem;
  }
  .desc_container{
    margin-top: 1.5rem;
  }
  p {
    margin-bottom: none;
  }
}