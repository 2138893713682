@import url('https://fonts.googleapis.com/css2?family=Rubik+Lines&display=swap');

.video-gallery {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .video-gallery {
    padding-top: 75%;
  }
}

@media screen and (max-width: 576px) {
  .video-gallery {
    padding-top: 100%;
  }
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.video-container.current {
  transform: translateX(0);
}

.video-container.next {
  transform: translateX(100%);
}

.video-container.transitioning.current {
  opacity: 0;
  transform: translateX(-100%);
}

.video-container.transitioning.next {
  opacity: 1;
  transform: translateX(0);
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  pointer-events: none;
}

.concept {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 4rem;
  font-family: 'Rubik Lines', sans-serif;
  line-height: 1.5;
  white-space: nowrap;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .concept {
    white-space: normal;
  }
}

@media screen and (max-width: 425px) {
  .concept {
    font-size: 3rem;
  }
}